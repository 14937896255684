








import Vue from "vue";
import SponsorManagement from "@/components/accountsManagement/SponsorManagement.vue";
import VoiceOfManagement from "@/components/accountsManagement/VoiceOfManagement.vue";

export default Vue.extend({
  components: { VoiceOfManagement },
  metaInfo() {
    return {
      link: [{ rel: "canonical", href: `${process.env.VUE_APP_BASE_URL}${this.$route.path}` }],
    };
  },
  computed: {
    accountTypeComponent: function() {
      switch (this.$route.params.accountType) {
        case "sponsor":
          return SponsorManagement;
        case "voiceof":
          return VoiceOfManagement;
        default:
          return null;
      }
    },
  },
});
