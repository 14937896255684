































































































import { CreateVoiceOfUser, UserStatus, VoiceOfAccountClient, VoiceOfUserResult } from "@/api/DoceoApi";
import Vue from "vue";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import { useErrorStore } from "@/store/errorStore";

export default Vue.extend({
  components: { DoceoIcon },

  mixins: [validationMixin],

  data: () => ({
    accounts: [] as VoiceOfUserResult[],
    accountsLoading: false,
    headers: [
      { text: "First Name", value: "firstName" },
      { text: "Last Name", value: "lastName" },
      { text: "Email", value: "email" },
      { text: "Status", value: "userStatus" },
      { text: "", value: "actions", sortable: false },
    ],
    addDialog: false,
    addLoading: false,
    newAccount: {
      firstName: "",
      lastName: "",
      email: "",
    },
    search: "",
    activateLoading: false,
    statuses: UserStatus,
    DOCEO_ICONS,
  }),

  validations: {
    newAccount: {
      firstName: {
        required,
      },
      email: {
        required,
        email: (val) => (email as any)(val.toLowerCase()),
      },
    },
  },

  mounted() {
    this.fetchAccounts();
  },

  methods: {
    /** Initializes the table by getting the list of accounts. */
    async fetchAccounts() {
      this.accountsLoading = true;
      try {
        const client = new VoiceOfAccountClient();
        this.accounts = await client.getVoiceOfUsers();
      } catch (e) {
        const errorStore = useErrorStore();
        errorStore.addError(e.detail);
      } finally {
        this.accountsLoading = false;
      }
    },
    /** Validates the form and calls the creates the account if valid. */
    submit() {
      this.$v.$touch();
      if (this.$v.newAccount.$invalid) return;
      this.addAccount();
    },
    /** Creates a new account. */
    async addAccount() {
      this.addLoading = true;
      try {
        const client = new VoiceOfAccountClient();

        const command = new CreateVoiceOfUser({
          firstName: this.newAccount.firstName,
          lastName: this.newAccount.lastName,
          email: this.newAccount.email,
        });

        const newUser = await client.create(command);
        this.accounts.push(newUser);

        this.closeDialog();
      } catch (e) {
        console.error(e);
        const errorStore = useErrorStore();
        errorStore.addError(e.detail);
      } finally {
        this.addLoading = false;
      }
    },
    /** Reactivates the specified account. */
    async reactivateAccount(user) {
      this.activateLoading = true;
      try {
        const client = new VoiceOfAccountClient();
        await client.reactivate(user.userId);
        user.userStatus = UserStatus.Approved;
      } finally {
        this.activateLoading = false;
      }
    },
    /** Deactivates the specified account. */
    async deactivateAccount(user) {
      this.activateLoading = true;
      try {
        const client = new VoiceOfAccountClient();
        await client.deactivate(user.userId);
        user.userStatus = UserStatus.Disabled;
      } finally {
        this.activateLoading = false;
      }
    },
    /** Closes the New Account dialog. */
    closeDialog() {
      this.addDialog = false;
      this.clear();
    },
    /** Clears the New Account form. */
    clear() {
      this.$v.$reset();
      this.newAccount.firstName = "";
      this.newAccount.lastName = "";
      this.newAccount.email = "";
    },
  },
});
